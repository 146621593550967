var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.show ? _c('div', {
    staticClass: "earn-wrapper"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('i', {
    staticClass: "iconfont icon-close",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.close.apply(null, arguments);
      }
    }
  })]), _c('div', {
    staticClass: "earn-content-wrapper"
  }, [_c('div', {
    staticClass: "img"
  }, [_c('p', [_c('i', {
    staticClass: "iconfont icon-book"
  }), _vm._v(_vm._s(_vm.styleType === 0 ? '采购＂赚钱＂攻略' : '安全用药 健康为民'))]), _c('img', {
    attrs: {
      "src": _vm.styleType === 0 ? _vm.ImgUrl : _vm.ImgUrl1
    }
  })])])]) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };