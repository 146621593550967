import "core-js/modules/es.number.constructor.js";
export default {
  created: function created() {},
  data: function data() {
    return {
      ImgUrl: "https://obs.pujian.com/frontend/app/order/ProcurementStrategy.png",
      ImgUrl1: "https://obs.pujian.com/frontend/pjyy/components/aqyyy.png"
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    styleType: {
      type: Number,
      default: 0
    }
  },
  watch: {
    show: function show(newV) {
      if (newV) {
        document.documentElement.style.overflowY = 'hidden';
      }
    }
  },
  methods: {
    close: function close() {
      document.documentElement.style.overflowY = 'auto';
      this.$emit('close');
    }
  }
};